import { ServicePricing, useAppState } from "../provider/AppStateProvider";
import {
  handleServiceClick,
  makeServiceKey,
} from "../utils/servicePickerUtils";
import {
  getPriceBreakdown,
  PriceBreakdown,
} from "../utils/servicePricingHelpers";
import QuoteOption from "./QuoteOption";

const OneOffServiceOption = ({
  servicePricing,
  handleOnClick,
}: {
  servicePricing: ServicePricing | null;
  handleOnClick?: (serviceKey: string, isSelected: boolean) => void;
}) => {
  const AppState = useAppState();

  let name = "";
  let title = "";
  let text = "";

  if (!servicePricing) return null;

  const serviceKey = makeServiceKey(servicePricing, "one-off");

  switch (servicePricing.serviceID) {
    case "service-gutterClean":
      name = "Gutter Cleaning";
      title = "Remove the clutter from your gutter";
      text =
        "Using our own generators and our 40ft high powered vacuum technology, we’ll clear out all your gutters within 10 days.";
      break;
    case "service-soffitClean":
      name = "Fascia Cleaning";
      title = "Give your frontage an extra sparkle!";
      text =
        "Using hot water and our special, deep clean brushes, we’ll give your fascias a much more detailed clean.";
      break;
  }

  const priceBreakdown: PriceBreakdown = getPriceBreakdown(
    servicePricing,
    AppState.numOfBedrooms,
    "one-off",
    AppState.hasConservatory ?? false
  );

  const isSelected: boolean = AppState.requestedServices.has(serviceKey);
  const isDiscounted = priceBreakdown.hasDiscount;
  const discountAmount = priceBreakdown.discountPercentage;
  const price = priceBreakdown.totalToPay.toFixed(2);

  return (
    <QuoteOption
      alignmentOnMobile="left"
      onOptionClicked={() => {
        if (handleOnClick) {
          handleOnClick(serviceKey, isSelected);
        }
        handleServiceClick(AppState, serviceKey, "one-off", servicePricing);
      }}
      isSelected={isSelected}
      topContent={() => (
        <div className={isSelected ? " text-white" : " text-primary"}>
          <p className="text-sm ">one off</p>
          <div className="flex justify-between place-items-center lg:flex-col">
            <span className="text-xl lg:text-3xl font-bold lg:mb-4">
              {name}
            </span>
            <span
              className={
                "text-2xl lg:text-3xl mt-2 font-bold " +
                (isSelected ? " text-white" : " text-action")
              }
            >
              £{price}
            </span>
          </div>
        </div>
      )}
      bottomContent={() => (
        <>
          {isDiscounted && (
            <span className="absolute -top-3 left-1/2 -ml-7 rounded bg-green-500 text-white text-xs px-2 py-1">
              {discountAmount}% off
            </span>
          )}
          <p className="text-sm text-primary font-bold mt-4">{title}</p>
          <p className="text-sm text-primary mt-2 ">{text}</p>
        </>
      )}
    />
  );
};

export default OneOffServiceOption;
