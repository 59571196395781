import { useNavigate } from "react-router";
import {
  RequestService,
  ServicePricing,
  useAppState,
} from "../provider/AppStateProvider";
import BackButton from "../components/BackButton";
import ScrollToTopOnMount from "../utils/ScrollHelpers";
import ProgressBar, { Step } from "../components/ProgressBar";
import { useState } from "react";
import Common from "../components/Common";
import NextButton from "../components/NextButton";
import { generateRequestedService } from "../utils/servicePickerUtils";

import WindowCleanOption from "../components/WindowCleanOption";

import OneOffServiceOption from "../components/OneOffCleanOption";
import ScrollDownIcon from "../components/ScrollDownIcon";

const YourQuote = () => {
  const AppState = useAppState();
  const navigateTo = useNavigate();
  const [existingPriceConfirmed, setExistingPriceConfirmed] = useState(
    !AppState.isFieldAudit
  ); // Only need to worry about this if its a Field Audit
  const [editablePrice, setEditablePrice] = useState(
    AppState.existingPrice + ""
  );

  // This is a bit tricky if we ever add in another service but it works, stitch together the services in the order that the user has asked for them/shown an interest

  const handleExistingPriceChange = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    console.info("Quoted price changed, local state");
    const newPriceRaw = event.currentTarget.value.trim();
    setEditablePrice(newPriceRaw);
  };

  const updateRequestedService = (newPrice: number) => {
    // This should only be done on FieldAudits so should be safe to assume we are just messing with the one and only service.
    console.info("Trying to update Requested Service");
    if (newPrice > -1) {
      console.info("Updating Requested Service");
      const firstEntry = AppState.requestedServices.entries().next().value;
      const key: string = firstEntry[0];
      const value: RequestService = firstEntry[1];

      AppState.removeRequestedService(key);
      AppState.addRequestedService(
        key,
        generateRequestedService(
          AppState,
          value.frequencyID,
          value.servicePricing,
          newPrice
        )
      );
    }
  };

  const handleFormSubmit = () => {
    updateRequestedService(AppState.existingPrice);
    if (
      !AppState.isFieldAudit &&
      !AppState.isTechSignUp &&
      AppState.showUpsellServices
    ) {
      navigateTo("/get-a-quote/just-for-you/");
    } else {
      navigateTo("/get-a-quote/sign-up/");
    }
  };

  // Work out what was requested
  let serviceIDAskedFor = AppState.preferredService || "service-hotWindowClean";

  // Find what was requested
  const preferredServicePricings = AppState.areaInfo?.servicePricing.filter(
    (sp) => sp.serviceID === serviceIDAskedFor
  );

  let firstServicePricing: ServicePricing | null = null;

  if (preferredServicePricings && preferredServicePricings[0]) {
    firstServicePricing = preferredServicePricings[0];
  }

  console.info("Requested Service info", {
    serviceIDAskedFor: serviceIDAskedFor,
    preferredServicePricings: preferredServicePricings,
    firstServicePricing: firstServicePricing,
    servicePricings: AppState.areaInfo?.servicePricing,
  });

  /* 
  A service can be passed in on the query string, that should be the "main" service 
  we show a quote for. After that we have the "just for you" screen which shows upsell 
  for the other remaining services. 
  */

  const isWindowClean = AppState.preferredService === "service-hotWindowClean";
  const isGutterClean = AppState.preferredService === "service-gutterClean";
  const isFasciaClean = AppState.preferredService === "service-soffitClean";

  return (
    <div className="flex flex-col justify-between min-h-screen md:h-auto md:min-h-0 md:pt-0 max-w-3xl m-auto">
      <ScrollToTopOnMount />
      <ProgressBar
        steps={
          [
            { name: "Your Property", status: "completed" },
            { name: "Quote Packages", status: "current" },
            { name: "Your Details", status: "upcoming" },
            { name: "Payment Method", status: "upcoming" },
          ] as Step[]
        }
      />
      <div className="mb-auto">
        {!AppState.isFieldAudit && (
          <>
            {!AppState.isTechSignUp && (
              <div className="mx-4">
                <Common.MainTitle>Your quote</Common.MainTitle>
                <Common.Text>
                  Select a quote package and press next to begin your booking
                </Common.Text>
              </div>
            )}
            {AppState.isTechSignUp && (
              <>
                <Common.MainTitle>Available services</Common.MainTitle>
                <Common.SubTitle>
                  Select which service the customer requires.
                </Common.SubTitle>
              </>
            )}
          </>
        )}
        {AppState.isFieldAudit && (
          <>
            <Common.MainTitle>Confirm service and price</Common.MainTitle>
            <Common.Text>
              <span className="font-bold">Expected service:</span>{" "}
              {AppState.existingRequestedServiceKey}
            </Common.Text>
            <Common.Text>
              <label className="my-6 block">
                <span className="font-bold">Existing Price: £</span>
                {!existingPriceConfirmed && (
                  <input
                    type="text"
                    value={editablePrice}
                    onChange={handleExistingPriceChange}
                    className="border border-gray-500 rounded-md ml-1 w-2/12"
                  />
                )}
                {existingPriceConfirmed && (
                  <span>{AppState.existingPrice}</span>
                )}
              </label>
              {!existingPriceConfirmed && (
                <button
                  onClick={() => {
                    setExistingPriceConfirmed(true);
                    AppState.setExistingPrice(Number(editablePrice));
                  }}
                  className="bg-yellow-500 rounded-lg text-white shadow-sm px-12 py-2 pc-bold-text"
                >
                  Confirm
                </button>
              )}
            </Common.Text>
          </>
        )}
        {existingPriceConfirmed && (
          <div>
            <div
              className={
                "grid gap-4 p-4 " +
                (isWindowClean ? "grid-cols-2" : "grid-cols-1")
              }
            >
              {isWindowClean && (
                <>
                  <WindowCleanOption
                    servicePricing={firstServicePricing}
                    frequencyID="four-weekly"
                    isUpselling={false}
                  />
                  <WindowCleanOption
                    servicePricing={firstServicePricing}
                    frequencyID="eight-weekly"
                    isUpselling={false}
                  />
                </>
              )}
              {isGutterClean && (
                <OneOffServiceOption servicePricing={firstServicePricing} />
              )}
              {isFasciaClean && (
                <OneOffServiceOption servicePricing={firstServicePricing} />
              )}
            </div>
            <div className="mx-4">
              <Common.SubTitle>How it works...</Common.SubTitle>
              <div className="flex flex-col text-center lg:flex-row justify-between lg:space-x-12">
                <Common.Text>
                  All packages are <b>contract free</b> meaning you can pause at
                  any time!
                </Common.Text>
                <Common.Text>
                  <b>We’ll text you</b> the day before we come to check that
                  everything is ok.
                </Common.Text>
                {isWindowClean && (
                  <Common.Text>
                    Each clean includes <b>all windows, frames, sills</b> and{" "}
                    <b>doors</b>
                  </Common.Text>
                )}
                {isGutterClean && (
                  <Common.Text>
                    Each clean includes your gutters and we dispose of the mess.
                  </Common.Text>
                )}
                {isFasciaClean && (
                  <Common.Text>
                    Each clean includes <b>all fascias and soffits</b>
                  </Common.Text>
                )}
              </div>
            </div>
          </div>
        )}
      </div>
      <div className="stickyButtonBar border-0 border-t-1">
        <div className="flex flex-1 justify-between items-center p-4 bg-biege lg:bg-transparent">
          <BackButton />
          <NextButton
            isActive={AppState.requestedServices.size > 0}
            handleOnClick={handleFormSubmit}
          />
        </div>
      </div>
    </div>
  );
};

export default YourQuote;
