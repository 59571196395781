export interface Step {
  name: string;
  url?: string;
  status?: "completed" | "current" | "upcoming";
  isUpsellStep?: boolean;
}

export interface ProgressBarProps {
  steps: Step[];
  className?: string;
}

const ProgressBar = (props: ProgressBarProps) => {
  if (props.steps.length === 0) return null;
  return (
    <nav
      aria-label="Progress"
      className={`${props.className} flex py-2 w-full mb-6`}
    >
      <ol className="flex justify-evenly w-full">
        {props.steps.map((step, index) => {
          const isFirstElement = index === 0;
          const isLastElement = index === props.steps.length - 1;
          return (
            <li
              className={
                "relative flex-1 " +
                (isFirstElement ? "ml-4" : "") +
                (isLastElement ? "mr-4" : "")
              }
              key={
                (step.isUpsellStep ? "upsell" : step.name) +
                step.status +
                step.url +
                index
              }
            >
              {/* Upcoming Step */}
              {(step.status || step.isUpsellStep) && (
                <div className="flex flex-col items-center text-center">
                  <div
                    className={
                      "flex flex-col justify-center" +
                      (step.isUpsellStep ? " mt-1.5" : "")
                    }
                  >
                    <div
                      className={
                        "absolute inset-x-0 flex items-center" +
                        (isFirstElement ? " left-1/2" : "") +
                        (isLastElement ? " right-1/2" : "")
                      }
                      aria-hidden="true"
                    >
                      <div className="h-0.5 w-full border-t-2 border-dashed border-action"></div>
                    </div>
                    {step.isUpsellStep && (
                      <span
                        className="h-3 w-3 rounded-full group-hover:bg-action bg-action"
                        aria-hidden="true"
                      ></span>
                    )}
                    {!step.isUpsellStep && (
                      <div
                        className={
                          "group relative w-6 h-6 flex items-center justify-center " +
                          (step.status === "completed"
                            ? "bg-action"
                            : "bg-white") +
                          " border-2 border-action rounded-full hover:border-yellow-600"
                        }
                      >
                        {step.status === "completed" && (
                          <svg
                            className="w-5 h-5 text-white"
                            xmlns="http://www.w3.org/2000/svg"
                            viewBox="0 0 20 20"
                            fill="currentColor"
                            aria-hidden="true"
                          >
                            <path
                              fillRule="evenodd"
                              d="M16.707 5.293a1 1 0 010 1.414l-8 8a1 1 0 01-1.414 0l-4-4a1 1 0 011.414-1.414L8 12.586l7.293-7.293a1 1 0 011.414 0z"
                              clipRule="evenodd"
                            />
                          </svg>
                        )}

                        {step.status === "current" && (
                          <span
                            className="h-2.5 w-2.5 rounded-full group-hover:bg-action bg-action"
                            aria-hidden="true"
                          ></span>
                        )}
                      </div>
                    )}
                  </div>
                  <span
                    className={
                      "block w-16 mt-2 text-center text-xs " +
                      (step.status === "current"
                        ? "font-bold text-primary"
                        : "text-light")
                    }
                  >
                    {step.name}
                  </span>
                </div>
              )}
            </li>
          );
        })}
      </ol>
    </nav>
  );
};

export default ProgressBar;
