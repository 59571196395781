import { useNavigate } from "react-router";
import { useAppState } from "../provider/AppStateProvider";
import BackButton from "../components/BackButton";
import ScrollToTopOnMount from "../utils/ScrollHelpers";
import Tracking from "../utils/Tracking";
import QuoteOption from "../components/QuoteOption";
import NextButton from "../components/NextButton";
import { useState } from "react";
import Common from "../components/Common";
import ScrollDownIcon from "../components/ScrollDownIcon";
import ProgressBar, { Step } from "../components/ProgressBar";

const PaymentMethod = () => {
  const AppState = useAppState();
  const navigateTo = useNavigate();

  const submitQuote = () => {
    if (!isSubmitting) {
      setIsSubmitting(true);
      AppState.submitDetailsToAPI()
        .then(() => {
          Tracking.quoteSubmitted("online");
          navigateTo("/get-a-quote/thank-you");
        })
        .catch((error: any) => {
          console.error(error);
        })
        .finally(() => setIsSubmitting(false));
    }
  };

  const [isSubmitting, setIsSubmitting] = useState(false);

  const methods = [
    {
      name: "Bank Transfer",
      paymentMethodID: "bankTransfer",
      description:
        "Our account details will be clearly labelled on every invoice, which we’ll post through your door once we’re done.",
    },
    {
      name: "Cash",
      paymentMethodID: "cash",
      description:
        "We’re happy to take cash payments from you, and we’re happy to collect it from a safe space incase you wanted to leave it out.",
    },
    {
      name: "Direct Debit",
      paymentMethodID: "directDebit",
      description:
        "We’ve partnered with GoCardless to ensure your payments are processed safely and securely.",
    },
  ];

  return (
    <div className="flex flex-col justify-between max-w-3xl m-auto min-h-screen md:h-auto md:min-h-0 md:pt-0">
      <ScrollToTopOnMount />
      <ProgressBar
        steps={
          [
            { name: "Your Property", status: "completed" },
            { name: "Quote Packages", status: "completed" },
            { name: "Your Details", status: "completed" },
            { name: "Payment Method", status: "current" },
          ] as Step[]
        }
      />
      <div className="w-full mb-auto">
        <Common.MainTitle>Payment Method</Common.MainTitle>
        <Common.Text>
          Please tell us how{" "}
          {AppState.isTechSignUp ? "the customer would" : "you'd"} like to pay
          for your cleaning service?
        </Common.Text>
        <div className="grid grid-cols-1 lg:grid-cols-3 gap-4">
          {methods.map((method) => (
            <QuoteOption
              onOptionClicked={() =>
                AppState.setPaymentMethod(method.paymentMethodID)
              }
              isSelected={AppState.paymentMethodID === method.paymentMethodID}
              topContent={() => (
                <span className="text-xl font-bold">{method.name}</span>
              )}
              bottomContent={() => (
                <Common.Text className="text-xs">
                  {method.description}
                </Common.Text>
              )}
            />
          ))}
        </div>
        {AppState.paymentMethodID === "cash" && (
          <div
            className={
              "mx-auto mt-2 lg:mt-4 max-w-lg" +
              (AppState.paymentMethodID === "cash" ? "" : " opacity-30")
            }
          >
            <label className="font-bold mb-2">
              Where is your safe space to leave cash payments?
              <textarea
                value={AppState.safeSpace}
                onChange={(e) => AppState.setSafeSpace(e.target.value)}
                placeholder="Describe the safe place"
                className="border border-light rounded-md p-2 h-40 w-full"
                disabled={AppState.paymentMethodID !== "cash"}
              />
            </label>
          </div>
        )}
      </div>
      <div className="stickyButtonBar">
        <div className="flex flex-1 justify-between items-center p-4 bg-biege lg:bg-transparent">
          <BackButton />
          <ScrollDownIcon
            isVisible={
              AppState.paymentMethodID === "cash" &&
              AppState.safeSpace === "" &&
              !isSubmitting
            }
          />
          <NextButton
            type="submit"
            isActive={AppState.paymentMethodID !== "" && !isSubmitting}
            handleOnClick={() => submitQuote()}
            text="Submit"
            isSubmitting={isSubmitting}
          />
        </div>
      </div>
    </div>
  );
};

export default PaymentMethod;
