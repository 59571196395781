import { useNavigate } from "react-router";
import { useAppState } from "../provider/AppStateProvider";
import ScrollToTopOnMount from "../utils/ScrollHelpers";
import ContactDetailsForm, {
  ContactDetailsSubmissionValues,
} from "../components/ContactDetailsForm";
import Tracking from "../utils/Tracking";
import ProgressBar, { Step } from "../components/ProgressBar";

/* New Customer contact details form */

const SignUpForm = () => {
  const AppState = useAppState();
  const navigateTo = useNavigate();

  const handleSubmit = (values: ContactDetailsSubmissionValues) => {
    const isCallback = false;
    Tracking.contactDetailsEntered("online");
    AppState.setContactDetails(values, isCallback);
    AppState.setMarketingOptIn(true); // Can't get past this step without opting in anyway so ok to hardcode
    navigateTo("/get-a-quote/payment-method");
  };

  return (
    <div className="flex flex-col justify-between min-h-screen md:h-auto md:min-h-0 md:pt-0 max-w-3xl m-auto">
      <ScrollToTopOnMount />
      <ProgressBar
        steps={
          [
            { name: "Your Property", status: "completed" },
            { name: "Quote Packages", status: "completed" },
            { name: "Your Details", status: "current" },
            { name: "Payment Method", status: "upcoming" },
          ] as Step[]
        }
      />
      <ContactDetailsForm handleSubmit={handleSubmit} />
    </div>
  );
};

export default SignUpForm;
