const BackButton = () => {
  return (
    <button
      className="border border-action bg-white rounded-full px-12 py-4 uppercase font-semibold hover:bg-action hover:text-white hover:font-bold"
      onClick={() => window.history.back()}
    >
      Back
    </button>
  );
};

export default BackButton;
