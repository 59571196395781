import { useNavigate } from "react-router";
import { useAppState } from "../provider/AppStateProvider";
import React, { useState, useRef, useEffect, useCallback } from "react";
import { isValid, toNormalised } from "postcode";
import { postcodeAreaLookup } from "../utils/api";
import { IoLocation } from "react-icons/io5";
import Tracking from "../utils/Tracking";
import NoPostcodeMatchModal from "../components/NoPostcodeMatchModal";
import Common from "../components/Common";

const PostCodeEntry = () => {
  const AppState = useAppState();
  const navigateTo = useNavigate();
  const passedInPostcode = AppState.postcode;
  const [postcodeIsValid, setPostcodeIsValid] = useState(true);
  const [postcode, setPostcode] = useState(passedInPostcode);
  const [haveDoneAreaSearch, setHaveDoneAreaSearch] = useState(false);
  const [searchSubmitted, setSearchSubmitted] = useState(false);

  const inputRef: React.MutableRefObject<null | HTMLInputElement> =
    useRef(null);

  const handleKeyPress = (event: React.KeyboardEvent<HTMLInputElement>) => {
    if (event.key === "Enter") {
      if (postcodeIsValid && postcode.length > 0) {
        handleOnClick();
      }
    }
  };

  const handleOnClick = useCallback(() => {
    //console.info("handleOnClick called");

    Tracking.quoteStarted();

    if (postcodeIsValid && postcode.length > 0) {
      //console.info("going to do the search");
      AppState.setPostcode(postcode);
      AppState.setAreaInfo(null); // clear out any old data
      setSearchSubmitted(true);
      postcodeAreaLookup(postcode).then((data) => {
        data.json().then((results) => {
          setHaveDoneAreaSearch(true);
          setSearchSubmitted(false);

          console.info("Did search", results);

          if (results) {
            if (results.error && results.error === "No area found") {
              AppState.setAreaInfo(null);
            } else {
              AppState.setAreaInfo(results);
              navigateTo("/get-a-quote/your-property");
            }
          }
        });
      });
    }
  }, [AppState, navigateTo, postcode, postcodeIsValid]);

  useEffect(() => {
    // Make the input have focus straight away
    setTimeout(() => {
      console.info("Setting focus");
      if (inputRef.current && inputRef.current.focus) {
        inputRef.current.focus();
      }
    }, 500);
  }, []);

  const handleOnChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    //console.info("key press!");
    const value = event.currentTarget.value.toUpperCase();
    if (value.length > 3) {
      const postcodeCleaned = value.trim(); // We don't do a trim before hand as they might be typing the " " between the postcode parts
      if (isValid(postcodeCleaned)) {
        setPostcodeIsValid(true);
        const normalistedPostcode = toNormalised(postcodeCleaned);
        if (normalistedPostcode) {
          setPostcodeIsValid(true);
          setPostcode(normalistedPostcode);
        } else {
          setPostcodeIsValid(false);
          setPostcode(value);
        }
      } else {
        setPostcodeIsValid(false);
        setPostcode(value);
      }
    } else {
      setPostcodeIsValid(false);
      setPostcode(value);
    }
  };

  const handleReset = () => {
    /*    AppState.setAreaInfo(null);
    AppState.setPostcode("");
    setSearchSubmitted(false);
    setHaveDoneAreaSearch(false);
    setPostcode("");
    */
    window.location.href = "/get-a-quote";
  };

  const showError = !postcodeIsValid && postcode.length > 0;
  const showSorry = haveDoneAreaSearch && AppState.areaInfo === null;

  console.group();
  console.info("Show sorry", showSorry);
  console.info("Postcode", postcode);
  console.info("postcodeIsValid", postcodeIsValid);
  console.info("searchSubmitted", searchSubmitted);
  console.groupEnd();
  console.info("isValid(AppState.postcode)", isValid(AppState.postcode));
  console.info("AppState.isParsingQueryString", AppState.isParsingQueryString);
  console.info("Area Info", AppState.areaInfo);

  // Don't render the first step if we've been pass something on the querystring
  if (
    !showSorry &&
    ((postcode && postcodeIsValid && searchSubmitted) ||
      (isValid(AppState.postcode) && !AppState.areaInfo) ||
      AppState.isParsingQueryString)
  ) {
    return (
      <div className="h-screen md:h-auto flex justify-center items-center text-xl pb-60">
        <div className="flex items-baseline">
          <IoLocation className="animate-bounce text-yellow-500" />
          <span className="pl-2 mb-2">Searching..</span>
        </div>
      </div>
    );
  }

  return (
    <div className="w-full">
      {showSorry && <NoPostcodeMatchModal postcode={postcode} />}
      {!showSorry && (
        <div className="text-primary">
          {!AppState.isTechSignUp && (
            <Common.MainTitle className="hidden lg:block lg:mb-12">
              Book your clean online today!
            </Common.MainTitle>
          )}
          <div className="grid grid-cols-1 lg:grid-cols-2">
            <div className="max-w-lg">
              <p className="py-2 text-center lg:text-left text-3xl font-light">
                Get your window clean booked in four simple steps!
              </p>
              <p className="py-2 text-center lg:text-left text-sm">
                We just need a few details so that we can give you a
                personalised quote.
              </p>
              <p className="py-2 text-center lg:text-left text-sm">
                If you’re happy with the quote then keep going and you’ll be
                able to book your clean online!
              </p>
            </div>
            <div className="w-full m-auto bg-biege py-8 px-12">
              <div className="max-w-lg flex flex-col justify-center space-y-4 m-auto">
                <input
                  type="text"
                  onChange={handleOnChange}
                  onKeyPress={handleKeyPress}
                  value={postcode}
                  className={
                    "rounded-lg p-4 my-2 border border-light " +
                    (showError ? " ring ring-red-300 ring-3" : " shadow-sm")
                  }
                  placeholder="Enter your postcode"
                  ref={inputRef}
                />

                <div className="flex justify-end">
                  {!searchSubmitted && (
                    <button
                      className={
                        "flex-1 rounded-full text-white font-medium text-lg shadow-sm px-5 py-4 uppercase" +
                        (showError
                          ? " cursor-not-allowed bg-extra-light border-light text-light border-2"
                          : " bg-action")
                      }
                      onClick={() => handleOnClick()}
                      disabled={showError}
                    >
                      GET STARTED
                    </button>
                  )}
                  {searchSubmitted && (
                    <span className="bg-gray-100 flex-1 text-center rounded-lg text-gray-400 shadow-sm px-12 py-6">
                      Searching...
                    </span>
                  )}
                </div>

                {showError && (
                  <span className="text-sm font-red-500 text-center">
                    Please enter a valid full postcode.
                  </span>
                )}
              </div>
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default PostCodeEntry;
