import React, { ReactNode } from "react";

const Text = ({
  children,
  className,
}: {
  children: ReactNode;
  className?: string;
}) => {
  return (
    <p className={`py-2 text-primary text-center text-sm ${className}`}>
      {children}
    </p>
  );
};

const MainTitle = ({
  children,
  className,
}: {
  children: ReactNode;
  className?: string;
}) => {
  return (
    <h2
      className={`py-4 mb-2 lg:mb-6 text-primary text-center text-4xl lg:text-6xl font-bold ${className}`}
    >
      {children}
    </h2>
  );
};

const SubTitle = ({
  children,
  className,
}: {
  children: ReactNode;
  className?: string;
}) => {
  return (
    <h3
      className={`py-4 text-primary text-center md:text-left text-xl md:text-2xl font-normal md:font-light ${className}`}
    >
      {children}
    </h3>
  );
};

const Elements = {
  Text,
  MainTitle,
  SubTitle,
};

export default Elements;
