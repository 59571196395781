import React, { ReactNode } from "react";

const QuoteOption = ({
  isSelected,
  topContent,
  bottomContent,
  onOptionClicked,
  alignmentOnMobile,
}: {
  isSelected: boolean;
  topContent: Function;
  bottomContent: Function;
  onOptionClicked: Function;
  alignmentOnMobile?: "left" | "center";
}) => {
  return (
    <div
      className="flex flex-col w-full h-full max-w-3xl m-auto"
      onClick={() => onOptionClicked()}
    >
      <div
        className={
          "flex flex-col flex-grow-0 bg-biege p-4 pb-4 rounded-t-lg border border-action " +
          (!isSelected ? "bg-biege" : "bg-action text-white") +
          (alignmentOnMobile === "left"
            ? " justify-start lg:justify-center lg:place-items-center lg:items-center"
            : "justify-center place-items-center items-center")
        }
      >
        {/* our radio button like circle */}
        <div className="group relative w-6 h-6 mb-2 flex items-center justify-center bg-white border-2 border-action rounded-full hover:border-yellow-600">
          {isSelected && (
            <span
              className={
                "h-2.5 w-2.5 rounded-full group-hover:bg-action" +
                (true ? " bg-action" : "bg-transparent")
              }
              aria-hidden="true"
            ></span>
          )}
        </div>
        {topContent()}
      </div>
      <div
        className={
          "flex flex-col flex-grow relative p-4 pt-4 bg-white rounded-b-lg border border-t-0 border-action " +
          (alignmentOnMobile === "left"
            ? " justify-start lg:justify-center lg:place-items-center lg:items-center"
            : " justify-center place-items-center items-center")
        }
      >
        {bottomContent()}
      </div>
    </div>
  );
};
export default QuoteOption;
