import React from "react";
import ReactDOM from "react-dom";
import "./index.css";
import App from "./App";
import reportWebVitals from "./reportWebVitals";
import { BrowserRouter as Router, Route, Routes } from "react-router-dom";
import PostcodeEntry from "./steps/PostcodeEntry";
import YourProperty from "./steps/YourProperty.tsx";
import YourQuote from "./steps/YourQuote.tsx";
import JustForYou from "./steps/JustForYou.tsx";
import SignUpForm from "./steps/SignUpForm.tsx";
import PaymentMethod from "./steps/PaymentMethod.tsx";
import ThankYou from "./steps/ThankYou.tsx";
import { AppStateProvider } from "./provider/AppStateProvider";
import smoothscroll from "smoothscroll-polyfill";
import * as Sentry from "@sentry/react";
import { Integrations } from "@sentry/tracing";

Sentry.init({
  dsn: "https://7f2cd1a1ab5d410db45d59fd548af590@o561585.ingest.sentry.io/5699093",
  integrations: [new Integrations.BrowserTracing()],

  // Set tracesSampleRate to 1.0 to capture 100%
  // of transactions for performance monitoring.
  // We recommend adjusting this value in production
  tracesSampleRate: 0.2,
});

smoothscroll.polyfill();

const targetContainerToRenderInto = document.getElementById("root");

if (targetContainerToRenderInto) {
  ReactDOM.render(
    <React.StrictMode>
      <AppStateProvider>
        <Router>
          <Routes>
            <Route element={<App />} path="/get-a-quote">
              <Route element={<PostcodeEntry />} path="" />
              <Route element={<YourProperty />} path="your-property" />
              <Route element={<YourQuote />} path="your-quote" />
              <Route element={<JustForYou />} path="just-for-you" />
              <Route element={<SignUpForm />} path="sign-up" />
              <Route element={<PaymentMethod />} path="payment-method" />
              <Route element={<ThankYou />} path="thank-you" />
            </Route>
          </Routes>
        </Router>
      </AppStateProvider>
    </React.StrictMode>,
    targetContainerToRenderInto
  );
}
// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
