import { ErrorMessage, Field } from "formik";
import { default as React } from "react";

export interface BaseInputFieldProps {
  inputName: string;
  labelText?: string;
  optional?: boolean;
  className?: string;
  inputType?: string;
}

export interface InputFieldProps extends BaseInputFieldProps {
  placeholder?: string | undefined;
  customError?: string;
}

const InputField = (props: InputFieldProps) => {
  const placeholder =
    (props.placeholder ?? "") + (props.optional ? " (optional)" : "");
  const inputType = props.inputType ?? "text";
  const inputID = "optional-" + props.inputName;

  return (
    <div className="flex flex-col text-sm text-primary mt-4">
      <label htmlFor={props.inputName} className="font-bold mb-2">
        {props.labelText}
        {props.optional}
      </label>
      <Field
        id={props.inputName}
        name={props.inputName}
        placeholder={placeholder}
        type={inputType}
        className={
          "rounded-lg p-4 my-0 border border-light focus:border-indigo-500" +
          (inputType === "textarea" ? " h-40" : " h-12")
        }
        aria-describedby={props.optional ? inputID : null}
        as={inputType === "textarea" ? "textarea" : "input"}
      />
      <ErrorMessage
        component="p"
        className="mt-2 text-sm text-red-600"
        name={props.inputName}
      />
      {props.customError && (
        <p className="mt-2 text-sm text-red-600">{props.customError}</p>
      )}
    </div>
  );
};

export default InputField;
