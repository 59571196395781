import { Outlet, useNavigate, useSearchParams } from "react-router-dom";
import { useEffect, useState } from "react";
import { useAppState, StartUpDataInput } from "./provider/AppStateProvider";
import { isValid, toNormalised } from "postcode";
import { postcodeAreaLookup } from "./utils/api";

/*

Its possible to switch this form to be in "Field Audit" mode which is used when we sign up new customers where we already have some partial 
details for (normally when we buy a round up). For these someone on the ground will go around and check all the addresses so need to validate 
and suppliment the data we have. To do that you can pass everything that we have on the querystring and the form should do the rest. 

http://localhost:3000/get-a-quote?postcode=S35%200AG&houseNumber=1&street=Mowson%20Cres&firstName=Joe&lastName=Bloggs&phoneNumber=011123456789&email=someone@hotmail.com&existingPrice=10&frequency=four-weekly&payment=bankTransfer&note=

*/

/** This is everything that can be passed in on the querystring to pre-populate the form, some of this is only used it over fields are present */
interface PassedInQueryStringParams {
  // General params, these can be used for any links
  postcode?: string;
  service?: "gutters" | "windows" | "soffits"; // What is the primary service the user was after? This is NOT used to preselect a service, it just change the order that services are shown

  // Field Agent params, these will be used if provided - currently we assume we are only doing window sign ups as they are existing Customers
  existingPrice?: string; // If this is provided then we will treat this as a Field Audit, if not provided then we ignore everything after this, this is how much they are currently paying
  houseNumber?: number;
  street?: string;
  firstName?: string;
  lastName?: string;
  phoneNumber?: string;
  email?: string;
  frequency?: "four-weekly" | "eight-weekly";
  payment?: "bankTransfer";
  note?: string; // Any notes about this job that we will save at the end

  // Referring technician details (used by TAPP)
  techID?: string;
}

function App() {
  const AppState = useAppState();
  const navigateTo = useNavigate();

  const [showAuditSummary, setShowAuditSummary] = useState(false);
  const [queryString] = useSearchParams({}); // This should be PassedInQueryStringParams but its not working
  const passedInService = queryString.get("service") ?? "windows";
  const passedInPostcode =
    queryString.get("postcode")?.trim().toUpperCase() ?? "";
  const firstName = queryString.get("firstName") ?? "";
  const lastName = queryString.get("lastName") ?? "";
  const phoneNumber = queryString.get("phoneNumber") ?? "";
  const email = queryString.get("email") ?? "";
  const existingPrice = queryString.get("existingPrice");
  const frequency = queryString.get("frequency");
  const paymentMethod = queryString.get("payment");
  const notes = queryString.get("note"); // Not the lack of plural here!
  const techID = queryString.get("techID") ?? "";
  const [houseNumber] = useState(queryString.get("houseNumber") ?? "");
  const [street] = useState(queryString.get("street") ?? "");

  // https://www.preenandclean.com/get-a-quote?postcode=S36%201AE&houseNumber=33&street=Arthur%20Road&firstName=&lastName=&phoneNumber=&email=&existingPrice=8&frequency=four-weekly&payment=&note=

  // Redirect if someone is trying to come in half way through
  /*useEffect(() => {
    console.info(
      "Checking if we need to Redirecting as we are mid way through the app"
    );
    if (
      AppState.areaInfo == null &&
      window.location.pathname !== "/get-a-quote/"
    ) {
      console.info("Redirecting as we are mid way throuhg the app");
      navigateTo(
        "/get-a-quote/" +
          (window.location.search !== "" ? window.location.search : "")
      );
    }
  }, [navigateTo, AppState.areaInfo]);
*/
  // Set passed in values from Querystring
  useEffect(() => {
    if (!AppState.isParsingQueryString) return;

    const savedData =
      window.sessionStorage.getItem(AppState.stateCacheKey) || undefined;

    console.info("Saved data loaded from session state", savedData);
    const startUpData: StartUpDataInput = savedData
      ? JSON.parse(savedData)
      : {};
    // This gets passed in from links within TAPP that we use for signing up customers via TAPP, the sign up will get recorded with the techID as the referrer
    if (techID !== "") {
      startUpData.techID = techID;
    }

    if (passedInService !== "") {
      console.info("Preferred Service", passedInService);
      startUpData.preferredService = passedInService;
    }

    if (passedInPostcode) {
      console.info("Got a postcode", passedInPostcode);
      const existingPostcode = passedInPostcode.trim().toUpperCase();
      if (existingPostcode) {
        // if valid go to next step
        if (isValid(existingPostcode)) {
          const normalistedPostcode = toNormalised(existingPostcode);
          if (normalistedPostcode) {
            startUpData.passedInPostCode = normalistedPostcode;
          }
        }
      }

      console.info("Doing postcode look up");

      postcodeAreaLookup(startUpData.passedInPostCode ?? passedInPostcode).then(
        (data) => {
          const windowCleanKey = "service-hotWindowClean";
          data.json().then((results) => {
            console.info("Got data from postcode lookup", results);
            let dataIsGood = false;
            if (results) {
              if (results.error && results.error === "No area found") {
                console.info("Postcode didn't match an area in the API");
                startUpData.areaInfo = undefined;
              } else {
                console.info("Postcode match for an area in the API");
                startUpData.areaInfo = results;
                console.info("Area info", results);
                dataIsGood = true;
              }
            }

            if (dataIsGood) {
              // We use the price to flag if its a Audit entry or not (ie someone out in the field validating all our newly bought customers when we've bought a round up)
              if (existingPrice !== null) {
                startUpData.isFieldAudit = true;
                window.isFieldAudit = true; // Set this globally so our tracking script knows to skip these interactions
                console.info("Got an existingPrice!", existingPrice);

                startUpData.contactDetails = {
                  firstName: firstName,
                  lastName: lastName,
                  phoneNumber: phoneNumber,
                  email: email,
                };

                if (existingPrice) {
                  startUpData.existingPrice = parseFloat(existingPrice.trim());
                }

                if (notes) {
                  startUpData.contactDetails.notes = "Imported: " + notes;
                }

                if (paymentMethod) {
                  // Only doing bank transfers for now as thats all we have in the import
                  if (paymentMethod === "bankTransfer") {
                    startUpData.paymentMethodID = paymentMethod;
                  }
                }

                if (frequency) {
                  startUpData.existingRequestedServiceKey =
                    windowCleanKey + "-" + frequency;
                }
              }

              AppState.setStartUpData(startUpData);
              console.info("Start Up Data", startUpData);
              navigateTo("/get-a-quote/your-property");
            } else {
              AppState.setAreaInfo(null);
              // AppState.setPostcode(passedInPostcode);
            }
          });
        }
      );
    } else {
      // Still want to save the passed in service, no redirect required though
      console.info(
        "Setting start up date without a postcode as none passed in"
      );
      AppState.setStartUpData(startUpData);
    }

    // AppState.setIsParsingQueryString(true);
  }, []);

  return (
    <div className="container m-auto mb-24 max-w-6xl px-0">
      <div className="md:mt-12 mt-6">
        {AppState.isTechSignUp && (
          <h2 className="text-lg text-center">
            <span className="font-bold">Tech signup</span>
          </h2>
        )}
        {AppState.isFieldAudit && (
          <div className="border border-gray-700 rounded-lg my-4 p-4 bg-gray-200">
            <h2 className="text-lg text-center">
              <span className="font-bold">Auditing:</span>{" "}
              {houseNumber + " " + street} (
              {showAuditSummary ? (
                <span
                  className="underline text-indigo-700"
                  onClick={() => setShowAuditSummary(false)}
                >
                  hide details
                </span>
              ) : (
                <span
                  className="underline text-indigo-700"
                  onClick={() => setShowAuditSummary(true)}
                >
                  show details
                </span>
              )}
              )
            </h2>
            {showAuditSummary && (
              <ul className="mx-auto">
                <li>
                  House No.: {houseNumber} {street}
                </li>
                <li>Postcode: {AppState.postcode}</li>
                <li>First Name: {AppState?.contactDetails?.firstName}</li>
                <li>Last Name: {AppState?.contactDetails?.lastName}</li>
                <li>Phone: {AppState?.contactDetails?.phoneNumber}</li>
                <li>Email: {AppState?.contactDetails?.email}</li>
                <li>Existing Price: £{AppState?.existingPrice}</li>
                <li>Frequency: {frequency}</li>
                <li>Pays by BACS?: {paymentMethod === "bankTransfer"}</li>
              </ul>
            )}
          </div>
        )}
        <Outlet />
      </div>
    </div>
  );
}

export default App;
