import React from "react";

const ScrollDownIcon = ({ isVisible }: { isVisible: boolean }) => {
  return isVisible ? (
    <span className="lg:hidden animate-bounce duration-1000 flex justify-center">
      <svg
        xmlns="http://www.w3.org/2000/svg"
        fill="none"
        viewBox="0 0 24 24"
        strokeWidth={1.5}
        stroke="currentColor"
        className="w-6 h-6 inline"
      >
        <path
          strokeLinecap="round"
          strokeLinejoin="round"
          d="M19.5 5.25l-7.5 7.5-7.5-7.5m15 6l-7.5 7.5-7.5-7.5"
        />
      </svg>
      <span> Scroll</span>
    </span>
  ) : null;
};

export default ScrollDownIcon;
