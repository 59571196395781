import { useEffect } from "react";

const ScrollToTopOnMount = () => {
  const location = window.location.href;

  useEffect(() => {
    console.info("Scrolling as href changed", location);
    window.setTimeout(() => {
      console.info("Scrolling to top");
      window.scrollTo(0, 140); // Allow some space for the main menu
    }, 100);
  }, [location]);

  return null;
};

export default ScrollToTopOnMount;
