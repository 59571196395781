import {
  ServicePricing,
  AppState,
  RequestService,
} from "../provider/AppStateProvider";
import {
  getPriceBreakdown,
  PriceBreakdown,
  PriceOverrides,
} from "./servicePricingHelpers";

const DEFAULT_DISCOUNT_DURATION_IN_MONTHS = 3;

export const makeServiceKey = (
  servicePricing: ServicePricing | null,
  frequencyID: string
) => {
  return servicePricing ? servicePricing.serviceID + "-" + frequencyID : "";
};

export const handleServiceClick = (
  appState: AppState,
  serviceKey: string,
  frequencyID: string | undefined,
  servicePricing: ServicePricing | null
) => {
  if (appState.requestedServices?.has(serviceKey)) {
    appState.removeRequestedService(serviceKey);
  } else {
    appState.addRequestedService(
      serviceKey,
      generateRequestedService(appState, frequencyID, servicePricing, null)
    );
  }
};

export const generateRequestedService = (
  appState: AppState,
  frequencyID: string | undefined,
  servicePricing: ServicePricing | null,
  existingPrice: number | null
): RequestService | null => {
  console.info("Generating a Requested Service", servicePricing);
  if (frequencyID !== undefined && servicePricing !== null) {
    let overrides: PriceOverrides | null = null;

    if (appState.isFieldAudit) {
      overrides = {
        fixedPrice: appState.existingPrice,
        discountRelativeExpiryMonths: DEFAULT_DISCOUNT_DURATION_IN_MONTHS,
      };
    }

    const isUpsell =
      appState.preferredService !== undefined &&
      servicePricing.serviceID === appState.preferredService;

    const priceBreakdown: PriceBreakdown = getPriceBreakdown(
      servicePricing,
      appState.numOfBedrooms,
      frequencyID,
      appState.hasConservatory ?? false,
      overrides
    );
    return {
      servicePricing: servicePricing,
      frequencyID: frequencyID,
      price: priceBreakdown.discountedTotalToPay || priceBreakdown.totalToPay,
      priceBreakdown:
        (appState.isFieldAudit
          ? "Agreed historic price of £" +
            existingPrice +
            ", will honor for {DEFAULT_DISCOUNT_DURATION_IN_MONTHS} months from sign on date. "
          : "") + priceBreakdown.calculationDetails,
      isUpsell: isUpsell,
    };
  }

  return null;
};

export const getPreferredServiceDisplayName = (
  preferredService: string | undefined
) => {
  switch (preferredService) {
    case "service-hotWindowClean":
      return "Window Clean";
    case "service-gutterClean":
      return "Gutter Clean";
    case "service-soffitClean":
      return "Fascia Clean";
    default:
      return "";
  }
};
