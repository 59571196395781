import { ServicePricing } from "../provider/AppStateProvider";

export const getBedroomModifer = (
  sp: ServicePricing,
  numberOfBedrooms: number
): number => {
  switch (numberOfBedrooms) {
    case 1:
      return sp.bedroomOneModifier ?? 0;
    case 2:
      return sp.bedroomTwoModifier ?? 0;
    case 3:
      return sp.bedroomThreeModifier ?? 0;
    case 4:
      return sp.bedroomFourModifier ?? 0;
    case 5:
      return sp.bedroomFiveModifier ?? 0;
    default:
      return 0;
  }
};

export interface PriceBreakdown {
  hasConservatory: boolean;
  conservatoryModifier: number;
  hasDiscount: boolean;
  discountPercentage: number;
  discountedTotalToPay: number;
  discountAmount: number;
  discountDurationInMonths: number;
  bedroomModifer: number;
  subTotal: number;
  irregModifierPrice: number;
  totalToPay: number;
  basePrice: number;
  calculationDetails: string;
}

/** We can use this to over-power the pricing logic, good for when we sign up someone from a bought round */
export interface PriceOverrides {
  fixedPrice: number;
  discountRelativeExpiryMonths: number;
}

export const getPriceBreakdown = (
  sp: ServicePricing,
  customersNumberOfBedrooms: number,
  customersFrequencyID: string,
  customerHasConservatory: boolean,
  overrides?: PriceOverrides | null
): PriceBreakdown => {
  const bedroomModifer = getBedroomModifer(sp, customersNumberOfBedrooms);
  const conservatoryModifier = customerHasConservatory
    ? sp.conservatoryModifier !== undefined
      ? sp.conservatoryModifier
      : 0
    : 0;
  let subTotal = sp.basePrice ? sp.basePrice + bedroomModifer : 0;
  if (customerHasConservatory) {
    subTotal += conservatoryModifier;
  }
  const irregModifierPrice =
    sp.irregularFrequencyPercentageModifier &&
    sp.irregularFrequencyPercentageModifier > 0 &&
    sp.regularFrequencyID !== customersFrequencyID
      ? (subTotal / 100) * sp.irregularFrequencyPercentageModifier
      : 0;
  let totalToPayWithoutDiscounts = subTotal + irregModifierPrice;
  let totalToPayWithDiscounts = totalToPayWithoutDiscounts; // Default is its the same

  let discountPercentage = sp.discountPercentage ?? 0;
  let discountedAmount =
    totalToPayWithoutDiscounts -
    (totalToPayWithoutDiscounts -
      (totalToPayWithoutDiscounts / 100) * discountPercentage);

  // If we have an override price now is the time to use it
  if (
    overrides &&
    overrides.discountRelativeExpiryMonths &&
    overrides.discountRelativeExpiryMonths > 0
  ) {
    totalToPayWithDiscounts = overrides.fixedPrice;
    discountPercentage = 0;
    discountedAmount = totalToPayWithoutDiscounts - overrides.fixedPrice;
  } else {
    totalToPayWithDiscounts =
      discountPercentage !== 0
        ? totalToPayWithoutDiscounts - discountedAmount
        : 0;
  }

  const hasDiscount: boolean =
    discountPercentage > 0 ||
    (overrides?.discountRelativeExpiryMonths !== undefined &&
      overrides.discountRelativeExpiryMonths > 0);

  const basePrice = sp.basePrice ?? 0;

  // Build a mega string showing what this means so we can include it in the service subscription
  let calculationDetails = "";
  if (hasDiscount) {
    calculationDetails =
      calculationDetails +
      " £" +
      totalToPayWithDiscounts.toFixed(2) +
      " (Offer price), £" +
      totalToPayWithoutDiscounts.toFixed(2) +
      " (Full price)";
  } else {
    calculationDetails =
      calculationDetails +
      " £" +
      totalToPayWithoutDiscounts.toFixed(2) +
      " (Full price)";
  }

  if (overrides?.discountRelativeExpiryMonths) {
    calculationDetails +=
      " for first " +
      overrides.discountRelativeExpiryMonths +
      " months from sign up date. ";
  }

  if (discountPercentage !== 0) {
    calculationDetails =
      calculationDetails +
      " Discounted by " +
      discountPercentage +
      "% (£" +
      discountedAmount.toFixed(2) +
      " per month)";

    if (
      sp.discountRelativeExpiryMonths !== undefined &&
      sp.discountRelativeExpiryMonths > 0
    ) {
      calculationDetails =
        calculationDetails +
        " for the first " +
        sp.discountRelativeExpiryMonths +
        " months.";
    }

    if (sp.discountAbsoluteExpiryDate !== undefined) {
      const date = new Date(Date.parse(sp.discountAbsoluteExpiryDate));
      if (date.getFullYear() !== 1970) {
        calculationDetails =
          calculationDetails +
          " until " +
          date.toLocaleString().split(",")[0] +
          ". ";
      }
    }

    if (
      sp.discountTotalCleansExpiry !== undefined &&
      sp.discountTotalCleansExpiry > 0
    ) {
      calculationDetails =
        calculationDetails +
        " for the first " +
        sp.discountTotalCleansExpiry +
        " cleans.";
    }

    calculationDetails =
      calculationDetails +
      " Going up to £" +
      totalToPayWithoutDiscounts.toFixed(2) +
      " every visit once the discount expires. ";
  }

  calculationDetails =
    calculationDetails +
    " Price is based on £" +
    basePrice.toFixed(2) +
    " base price";

  if (bedroomModifer > 0) {
    calculationDetails =
      calculationDetails +
      " " +
      bedroomModifer.toFixed(2) +
      " bedroom modifier";
  }

  if (customerHasConservatory && conservatoryModifier > 0) {
    calculationDetails =
      calculationDetails +
      " " +
      conservatoryModifier.toFixed(2) +
      " for conservatory clean";
  }

  if (
    sp.irregularFrequencyPercentageModifier !== undefined &&
    sp.irregularFrequencyPercentageModifier > 0
  ) {
    calculationDetails =
      calculationDetails +
      " " +
      sp.irregularFrequencyPercentageModifier +
      "% (£" +
      irregModifierPrice.toFixed(2) +
      " irregular frequency modifier)";
  }

  return {
    hasConservatory: customerHasConservatory,
    conservatoryModifier: conservatoryModifier,
    hasDiscount: hasDiscount,
    discountPercentage: discountPercentage,
    discountedTotalToPay: totalToPayWithDiscounts,
    discountAmount: discountedAmount,
    discountDurationInMonths: sp.discountRelativeExpiryMonths || 0,
    bedroomModifer: bedroomModifer,
    subTotal: subTotal,
    irregModifierPrice: irregModifierPrice,
    totalToPay: totalToPayWithoutDiscounts,
    basePrice: basePrice,
    calculationDetails: calculationDetails,
  };
};
