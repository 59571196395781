import React from "react";

interface ActionButtonProps {
  text: string;
  value: string;
  handleOnClick: Function;
  isActive: boolean;
}

const ActionButton = (props: ActionButtonProps) => {
  return (
    <button
      value={props.value}
      onClick={() => props.handleOnClick(props.value)}
      className={
        "py-3 px-3 w-full block border border-action text-sm lg:text-base font-semibold lg:font-bold rounded-md h-14" +
        (props.isActive ? " bg-action text-white" : " border-stone")
      }
    >
      {props.text}
    </button>
  );
};

export default ActionButton;
