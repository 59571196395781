import ActionButton from "../components/ActionButton";
import { useNavigate } from "react-router";
import { useAppState } from "../provider/AppStateProvider";
import BackButton from "../components/BackButton";
import ScrollToTopOnMount from "../utils/ScrollHelpers";
import ProgressBar, { Step } from "../components/ProgressBar";
import Tracking from "../utils/Tracking";
import Common from "../components/Common";
import NextButton from "../components/NextButton";
import ScrollDownIcon from "../components/ScrollDownIcon";
import TooManyBedroomsModal from "../components/TooManyBedroomsModal";

/* What sort of house do you have? */

const YourProperty = () => {
  const AppState = useAppState();
  const navigateTo = useNavigate();

  const handleNextButton = () => {
    Tracking.priceDisplayed();
    navigateTo("/get-a-quote/your-quote");
  };

  return (
    <div className="flex flex-col justify-between pb-24 md:pt-0 max-w-3xl m-auto min-h-screen md:h-auto md:min-h-0">
      <ScrollToTopOnMount />
      {AppState.numOfBedrooms > 5 && <TooManyBedroomsModal />}
      <ProgressBar
        steps={
          [
            { name: "Your Property", status: "current" },
            { name: "Quote Packages", status: "upcoming" },
            { name: "Your Details", status: "upcoming" },
            { name: "Payment Method", status: "upcoming" },
          ] as Step[]
        }
      />
      <div className="w-full mb-auto">
        {!AppState.isTechSignUp && (
          <Common.MainTitle>Your property</Common.MainTitle>
        )}
        <Common.Text>What kind of property do you have?</Common.Text>
        <div className="grid grid-cols-2 gap-2">
          {AppState.houseTypeOptions.map((option) => {
            return (
              <ActionButton
                text={option.text}
                value={option.value}
                handleOnClick={(houseTypeID: string) =>
                  AppState.setHouseTypeID(houseTypeID)
                }
                isActive={AppState.houseTypeID === option.value}
                key={option.value}
              />
            );
          })}
        </div>

        <div className="mt-8">
          <Common.Text>How many bedrooms are there?</Common.Text>
          <div className="grid grid-cols-6">
            {[...Array(6)].map((_, num) => {
              const numberOfBedrooms = num + 1;
              let additionalClasses = "";
              if (numberOfBedrooms === 1) {
                additionalClasses += "rounded-l-lg ";
              }
              if (numberOfBedrooms === 6) {
                additionalClasses += "rounded-r-lg ";
              }
              if (numberOfBedrooms === AppState.numOfBedrooms) {
                additionalClasses += "bg-action text-white ";
              } else {
                additionalClasses += "bg-biege ";
              }
              return (
                <button
                  className={`border-action border p-4 ${additionalClasses}`}
                  onClick={() => AppState.setNumOfBedrooms(numberOfBedrooms)}
                >
                  {numberOfBedrooms + (numberOfBedrooms === 6 ? "+" : "")}
                </button>
              );
            })}
          </div>
        </div>

        <div className="mt-8">
          <Common.Text>
            Has {AppState.isTechSignUp ? "the" : "your"} property got a
            conversatory?
          </Common.Text>
          <div className="grid grid-cols-1 gap-2">
            <ActionButton
              text="Yes"
              value="true"
              handleOnClick={() => AppState.setHasConservatory(true)}
              isActive={AppState.hasConservatory === true}
            />
            <ActionButton
              text="No"
              value="false"
              handleOnClick={() => AppState.setHasConservatory(false)}
              isActive={AppState.hasConservatory == false}
            />
          </div>
        </div>
      </div>
      <div className="stickyButtonBar ">
        <div className="flex flex-1 justify-between items-center p-4 bg-biege lg:bg-transparent">
          <BackButton />

          <ScrollDownIcon
            isVisible={
              AppState.numOfBedrooms > 0 &&
              AppState.hasConservatory === undefined
            }
          />

          <NextButton
            handleOnClick={handleNextButton}
            isActive={
              AppState.numOfBedrooms > 0 &&
              AppState.numOfBedrooms < 6 &&
              AppState.houseTypeID !== "" &&
              AppState.hasConservatory !== undefined
            }
          />
        </div>
      </div>
    </div>
  );
};

export default YourProperty;
