import Common from "../components/Common";
import NextButton from "../components/NextButton";
import { useAppState } from "../provider/AppStateProvider";
import ScrollToTopOnMount from "../utils/ScrollHelpers";

const ThankYou = () => {
  const AppState = useAppState();
  return (
    <div className="flex flex-col justify-between min-h-screen md:h-auto md:min-h-0 md:h-auto md:pt-0 max-w-3xl m-auto">
      <ScrollToTopOnMount />
      <div className="mb-auto text-center px-8">
        <div className="flex items-center justify-center">
          <span className="relative w-16 h-16 flex flex-none items-center bg-light justify-center">
            <svg
              className="w-8 h-8 text-white"
              xmlns="http://www.w3.org/2000/svg"
              viewBox="0 0 20 20"
              fill="currentColor"
              aria-hidden="true"
            >
              {/* Heroicon name: solid/check */}
              <path
                fillRule="evenodd"
                d="M16.707 5.293a1 1 0 010 1.414l-8 8a1 1 0 01-1.414 0l-4-4a1 1 0 011.414-1.414L8 12.586l7.293-7.293a1 1 0 011.414 0z"
                clipRule="evenodd"
              />
            </svg>
          </span>
        </div>
        <Common.MainTitle className="text-xl mt-12">
          Congratulations!
        </Common.MainTitle>
        {AppState.isTechSignUp && (
          <>
            <p className="text-sm py-2">Customer has been added.</p>
            {AppState.instantClean && (
              <p className="text-sm py-2">
                A visit is being generated for this clean now within TAPP, you
                can start the clean now and reload TAPP in a few minutes to see
                it in your round.
              </p>
            )}
            {!AppState.instantClean && (
              <p className="text-sm py-2">
                A CAM will get this customer into the Round at the next
                available time.
              </p>
            )}
          </>
        )}
        {!AppState.isTechSignUp && (
          <>
            <Common.Text>
              Thank you for choosing Preen + Clean, your booking has been
              confirmed!
            </Common.Text>
            <Common.Text>
              One of the team will be in touch with you during the next working
              day.
            </Common.Text>
            <Common.Text className="mt-12 font-bold">
              If you have any questions...
            </Common.Text>
            <Common.Text>
              Please feel free to{" "}
              <a
                href="/contact/"
                className="underline text-dark hover:text-light cursor-pointer"
              >
                contact us
              </a>
              . Our normal office hours are:
            </Common.Text>
            <Common.Text>
              Monday - Friday
              <br />
              09:00am - 05:00pm
            </Common.Text>
            <Common.Text className="text-light">
              (excluding bank holidays)
            </Common.Text>
          </>
        )}
      </div>
      <div className="stickyButtonBar">
        <div
          className={
            "flex flex-1 " +
            (AppState.isTechSignUp ? "justify-between" : "justify-center") +
            " items-center p-4 bg-biege lg:bg-transparent"
          }
        >
          {AppState.isTechSignUp && (
            <button
              className="ring ring-light rounded-full bg-white text-primary font-semibold p-2 px-8 uppercase hover:bg-light hover:text-white transition-colors duration-200 ease-in-out"
              onClick={() =>
                (window.location.href = "https://admin.preenandclean.com/tapp/")
              }
            >
              Back to TAPP
            </button>
          )}
          <button
            className="ring ring-light rounded-full bg-white text-primary font-semibold p-2 px-8 uppercase hover:bg-light hover:text-white transition-colors duration-200 ease-in-out"
            onClick={() => (window.location.href = "/")}
          >
            {AppState.isTechSignUp ? "Home" : "Back to Homepage"}
          </button>
        </div>
      </div>
    </div>
  );
};

export default ThankYou;
