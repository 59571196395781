export const postcodeAreaLookup = async (postcodeToSearch: string) => {
  // console.info("In the API method");

  // Hardcoded to live as the dev prices are wrong
  const endPoint =
    // false && window.location.hostname === "localhost"
    //   ? "https://v8dfxr882c.execute-api.eu-west-1.amazonaws.com/localdev"
    //    :
    "https://w0xunu1dv7.execute-api.eu-west-1.amazonaws.com/prod";
  //   : "https://pi0mf6ktni.execute-api.eu-west-1.amazonaws.com/live";

  const response = await fetch(
    endPoint + "/areaLookup?postcode=" + postcodeToSearch,
    {
      mode: "cors",
      headers: {
        "Content-Type": "application/json",
      },
    }
  );

  console.info("Data", response);

  return response;
};
