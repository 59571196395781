import { useNavigate } from "react-router";
import { useAppState } from "../provider/AppStateProvider";
import BackButton from "../components/BackButton";
import ScrollToTopOnMount from "../utils/ScrollHelpers";
import ProgressBar, { Step } from "../components/ProgressBar";
import Common from "../components/Common";
import NextButton from "../components/NextButton";
import { getPreferredServiceDisplayName } from "../utils/servicePickerUtils";
import WindowCleanOption from "../components/WindowCleanOption";
import OneOffServiceOption from "../components/OneOffCleanOption";
import { useState } from "react";
import QuoteOption from "../components/QuoteOption";
import ScrollDownIcon from "../components/ScrollDownIcon";

const JustForYou = () => {
  const AppState = useAppState();
  const navigateTo = useNavigate();

  const handleFormSubmit = () => {
    navigateTo("/get-a-quote/sign-up");
  };

  const [skippedUpsell, setSkippedUpsell] = useState(false);

  let remainingServices = AppState.upsellServices();

  console.info("Requested Service info", {
    remainingServices,
    selectedServices: AppState.requestedServices,
    servicePricings: AppState.areaInfo?.servicePricing,
  });

  /* 
  A service can be passed in on the query string, that should be the "main" service 
  we show a quote for. After that we have the "just for you" screen which shows upsell 
  for the other remaining services. 
  */

  return (
    <div className="flex flex-col justify-between h-auto md:pt-0 max-w-3xl m-auto min-h-screen md:h-auto md:min-h-0">
      <ScrollToTopOnMount />
      <ProgressBar
        steps={
          [
            { name: "Your Property", status: "completed" },
            { name: "Quote Packages", status: "current" },
            { isUpsellStep: true },
            { name: "Your Details", status: "upcoming" },
            { name: "Payment Method", status: "upcoming" },
          ] as Step[]
        }
      />
      <div className="mb-auto">
        <div className="mx-4">
          <Common.MainTitle>Other services</Common.MainTitle>
          <Common.SubTitle>
            We have some special offers because you selected our{" "}
            {getPreferredServiceDisplayName(AppState.preferredService)} package.
          </Common.SubTitle>
          <Common.Text>
            Select them to add to your booking, <br />
            or click next to continue
          </Common.Text>
        </div>

        <div>
          <div className="grid grid-cols-1 lg:grid-cols-2 gap-4 p-4 ">
            {remainingServices?.map((servicePricing) => {
              if (servicePricing.serviceID === "service-conservatoryClean") {
                return null;
              }
              if (
                servicePricing.serviceID === "service-gutterClean" ||
                servicePricing.serviceID === "service-soffitClean"
              ) {
                return (
                  <OneOffServiceOption
                    servicePricing={servicePricing}
                    handleOnClick={(serviceKey, isSelected) =>
                      setSkippedUpsell(false)
                    }
                  />
                );
              }
              if (servicePricing.serviceID === "service-hotWindowClean") {
                return (
                  <>
                    <WindowCleanOption
                      servicePricing={servicePricing}
                      frequencyID="four-weekly"
                      isUpselling={true}
                      handleOnClick={(serviceKey, isSelected) =>
                        setSkippedUpsell(false)
                      }
                    />
                    <WindowCleanOption
                      servicePricing={servicePricing}
                      frequencyID="eight-weekly"
                      isUpselling={true}
                      handleOnClick={(serviceKey, isSelected) =>
                        setSkippedUpsell(false)
                      }
                    />
                  </>
                );
              }
            })}

            <QuoteOption
              alignmentOnMobile="left"
              onOptionClicked={() => {
                setSkippedUpsell((skipState) => !skipState);
                AppState.resetUpsellServices();
              }}
              isSelected={skippedUpsell}
              topContent={() => (
                <div className={skippedUpsell ? "text-white" : "text-primary"}>
                  <div className="flex justify-between place-items-center lg:flex-col">
                    <span className="text-xl lg:text-3xl font-bold lg:mb-4 ">
                      No thanks
                    </span>
                  </div>
                </div>
              )}
              bottomContent={() => (
                <p className="text-sm text-primary">
                  Continue without any other services
                </p>
              )}
            />
          </div>
        </div>
      </div>
      <div className="stickyButtonBar border-0 border-t-1">
        <div className="flex flex-1 justify-between items-center p-4 bg-biege lg:bg-transparent">
          <BackButton />
          <ScrollDownIcon isVisible={true} />
          <NextButton
            isActive={skippedUpsell || AppState.requestedServices.size > 1}
            handleOnClick={handleFormSubmit}
          />
        </div>
      </div>
    </div>
  );
};

export default JustForYou;
