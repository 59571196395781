import { ServicePricing, useAppState } from "../provider/AppStateProvider";
import {
  handleServiceClick,
  makeServiceKey,
} from "../utils/servicePickerUtils";
import {
  getPriceBreakdown,
  PriceBreakdown,
} from "../utils/servicePricingHelpers";
import QuoteOption from "./QuoteOption";

const WindowCleanOption = ({
  servicePricing,
  frequencyID,
  isUpselling,
  handleOnClick,
}: {
  servicePricing: ServicePricing | null;
  frequencyID: "four-weekly" | "eight-weekly";
  isUpselling: boolean;
  handleOnClick?: (serviceKey: string, isSelected: boolean) => void;
}) => {
  const AppState = useAppState();

  if (!servicePricing) return null;

  const serviceKey = makeServiceKey(servicePricing, frequencyID);

  const priceBreakdown: PriceBreakdown = getPriceBreakdown(
    servicePricing,
    AppState.numOfBedrooms,
    frequencyID,
    AppState.hasConservatory ?? false
  );

  const isSelected: boolean = AppState.requestedServices.has(serviceKey);
  const numOfWeeks = frequencyID === "four-weekly" ? 4 : 8;
  const isDiscounted = priceBreakdown.hasDiscount;
  const discountAmount = priceBreakdown.discountPercentage;
  const discountedPrice = priceBreakdown.discountedTotalToPay;
  const fullPrice = priceBreakdown.totalToPay;
  const price = (
    priceBreakdown.hasDiscount ? discountedPrice : fullPrice
  ).toFixed(2);

  return (
    <QuoteOption
      alignmentOnMobile={isUpselling ? "left" : "center"}
      onOptionClicked={() => {
        if (handleOnClick) {
          handleOnClick(serviceKey, isSelected);
        }
        handleServiceClick(AppState, serviceKey, frequencyID, servicePricing);
      }}
      isSelected={isSelected}
      topContent={() => (
        <div className={isSelected ? "text-white" : "text-primary"}>
          {isUpselling && (
            <div className="flex justify-between place-items-center lg:flex-col">
              <span className="text-xl lg:text-3xl font-bold lg:mb-4 ">
                Hot Wash Window Clean
              </span>
              <div className="hidden lg:flex justify-between items-center w-full">
                <div className="flex flex-col">
                  <p className="text-sm ">cleaned every</p>
                  <p className="text-xl lg:text-3xl font-bold mb-4 ">
                    {numOfWeeks} weeks
                  </p>
                </div>
                <span className="text-2xl lg:text-3xl mt-2 font-bold text-action">
                  £{price}
                </span>
              </div>
            </div>
          )}
          {!isUpselling && (
            <div className="flex flex-col justify-center text-center">
              <span className="text-xl font-bold lg:mb-4 ">
                Hot Wash Window Clean
              </span>
              <p className="text-sm ">cleaned every</p>
              <p className="text-xl font-bold mb-4 ">{numOfWeeks} weeks</p>
            </div>
          )}
        </div>
      )}
      bottomContent={() => (
        <>
          {isDiscounted && (
            <span className="absolute -top-3 rounded bg-green-500 text-white text-xs px-2 py-1">
              {discountAmount}% introductory offer
            </span>
          )}
          {isUpselling && (
            <>
              <p className="text-sm text-primary font-bold">Recurring clean</p>
              <p className="text-sm text-primary mt-2 ">
                Each clean includes <b>all windows, frames, sills</b> and{" "}
                <b>doors</b>
              </p>
            </>
          )}
          {!isUpselling && (
            <div className="text-center">
              <p className="text-xl lg:text-3xl my-3 font-bold text-action">
                £{price}
              </p>
              {isDiscounted && (
                <p className="text-xs text-primary">
                  for each of your <b>first 3 cleans</b>,{" "}
                  <b className="text-action">£{fullPrice.toFixed(2)}</b> after
                  that.
                </p>
              )}
              {!isDiscounted && (
                <p className="text-sm text-primary">per clean.</p>
              )}
            </div>
          )}
        </>
      )}
    />
  );
};

export default WindowCleanOption;
