import { Address } from "@ideal-postcodes/api-typings";
import { Client as PostcodeLookupClient } from "@ideal-postcodes/core-browser";
import * as Sentry from "@sentry/react";
import { ErrorMessage, Field, Form, FormikProps, withFormik } from "formik";
import React, { useEffect, useReducer } from "react";
import { Link } from "react-router-dom";
import * as Yup from "yup";
import { ContactDetails, useAppState } from "../provider/AppStateProvider";
import ScrollToTopOnMount from "../utils/ScrollHelpers";
import BackButton from "./BackButton";
import Common from "./Common";
import InputField from "./InputField";
import NextButton from "./NextButton";
import ScrollDownIcon from "./ScrollDownIcon";

interface FormProps {
  handleSubmit: Function;
  addressOptions: Address[];
  postcode: string;
  postcodeIsValid: boolean;
  postcodeLookupErrored: boolean;
  intialContactDetails: ContactDetails | null;
}

export interface ContactDetailsSubmissionValues {
  firstName: string;
  lastName: string;
  phoneNumber: string;
  email?: string;
  address?: Address;
  notes?: string;
}

interface ContactDetailsFormInputValue {
  firstName: string;
  lastName: string;
  addressID: number;
  email: string;
  phoneNumber: string;
  notes: string;
}

const ContactDetailsFormSchema = Yup.object({
  firstName: Yup.string().trim().required("Required"),
  lastName: Yup.string().trim().required("Required"),
  email: Yup.string().email(),
  phoneNumber: Yup.string().trim().min(11).max(16).required("Required"),
  addressID: Yup.number()
    .moreThan(-1, "Please select your address")
    .required("Please select your address"),
  marketingOptIn: Yup.boolean().oneOf([true], "Required"),
});

type State = {
  addressOptions: Address[];
  postcodeIsValid: boolean;
  postcodeLookupErrored: boolean;
};

const initialState: State = {
  addressOptions: [],
  postcodeIsValid: true,
  postcodeLookupErrored: false,
};

function reducer(state: State, action: Action): State {
  switch (action.type) {
    case "gotAddresses":
      return {
        ...state,
        addressOptions: action.addresses,
        postcodeIsValid: true,
        postcodeLookupErrored: false,
      };
    case "postcodeLookupErrored":
      return {
        ...state,
        postcodeLookupErrored: true,
      };
    case "postcodeUnknown":
      return {
        ...state,
        postcodeIsValid: false,
      };
    default:
      throw new Error();
  }
}

type Action =
  | { type: "gotAddresses"; addresses: Address[] }
  | { type: "postcodeUnknown" }
  | { type: "postcodeLookupErrored" };

const ContactDetailsForm = (props: {
  handleSubmit(values: ContactDetailsSubmissionValues): void;
}) => {
  const AppState = useAppState();

  const [{ addressOptions, postcodeIsValid, postcodeLookupErrored }, dispatch] =
    useReducer(reducer, initialState);

  const APIKey = AppState.postcodeLookupAPIKey;

  useEffect(() => {
    const postCodeLookup = new PostcodeLookupClient({
      api_key: APIKey,
      timeout: 29000,
    });

    const getAddressesByPostcode = async (
      postcode: string,
      maxAttempts: number = 3
    ) => {
      let addresses: Address[] = [];

      function wait(milliseconds: number) {
        return new Promise((resolve) => setTimeout(resolve, milliseconds));
      }

      for (let attempts = 0; attempts < maxAttempts; attempts++) {
        try {
          addresses = await postCodeLookup.lookupPostcode({ postcode });
          // Request was successfull, break out of loop
          break;
        } catch (error) {
          if (
            error instanceof PostcodeLookupClient.errors.IdpcRequestFailedError
          ) {
            // IdpcRequestFailedError indicates a 402 response code
            // Possibly the key balance has been depleted
            console.warn(
              "Looks like we might have run out of credits or hit our daily limits for IdealPostcode lookups?"
            );
          }
          console.warn("Error in postcode lookup", error);

          Sentry.captureException(error);
          Sentry.captureMessage(
            "Error with postcode lookup for postcode [" + postcode + "]"
          );
          dispatch({ type: "postcodeLookupErrored" });
        }

        await wait(500); // a delay before we try again, this won't run if successful so safe to live here
      }

      //console.info("Addresses for " + postcode, addresses);

      return addresses;
    };

    getAddressesByPostcode(AppState.postcode).then((addresses) => {
      if (addresses.length === 0) {
        dispatch({ type: "postcodeUnknown" });
      } else {
        dispatch({ type: "gotAddresses", addresses: addresses });
      }
    });
  }, [AppState.postcode, APIKey]);

  const handleSubmit = async (values: ContactDetailsFormInputValue) => {
    if (!values) return;

    const usersAddress = addressOptions.find((addr) => {
      return addr.udprn + "" === values.addressID + "";
    });

    props.handleSubmit({
      firstName: values.firstName,
      lastName: values.lastName,
      phoneNumber: values.phoneNumber,
      email: values.email,
      address: usersAddress,
      notes: values.notes,
    });
  };

  return (
    <ContactDetailsFormWrapper
      handleSubmit={handleSubmit}
      addressOptions={addressOptions}
      postcode={AppState.postcode}
      postcodeIsValid={postcodeIsValid}
      postcodeLookupErrored={postcodeLookupErrored}
      intialContactDetails={AppState.contactDetails}
    />
  );
};

const ContactDetailsFormUI = (
  props: FormProps & FormikProps<ContactDetailsFormInputValue>
) => {
  const {
    errors,
    addressOptions,
    values,
    initialValues,
    postcode,
    isSubmitting,
    postcodeIsValid,
    postcodeLookupErrored,
    handleChange,
  } = props;

  let formHasValues = false;
  if (values && initialValues !== values) {
    formHasValues = true;
  }

  let hasErrors = false;
  if (errors && Object.keys(errors).length) {
    console.warn("Form validation errors", errors);
    hasErrors = true;
  } else {
    //console.info("Form is now valid!");
  }

  return (
    <Form className="flex flex-col justify-between px-2 md:pt-0 max-w-3xl m-auto">
      <ScrollToTopOnMount />
      {addressOptions.length === 0 &&
        postcodeIsValid &&
        !postcodeLookupErrored && <></>}
      {postcodeLookupErrored ? (
        <div className="grid grid-1 items-center">
          <p className="text-sm p-2 my-0">
            Our address look up failed to respond in time so we cannot look up
            your address.
          </p>
          <a
            href="/get-a-quote/"
            className="bg-action rounded-full text-white font-bold px-12 py-2 text-center"
          >
            Try Again
          </a>
        </div>
      ) : (
        <>
          {addressOptions.length === 0 && !postcodeIsValid && (
            <div className="grid grid-1 items-center">
              <p className="text-sm p-2 my-0">
                We can't find any addresses that match postcode{" "}
                <b>{postcode}</b>. Please check your postcode is entered
                correctly.
              </p>
              <a
                href="/get-a-quote/"
                className="bg-action rounded-full text-white font-bold px-12 py-2 text-center"
              >
                Try Again
              </a>
            </div>
          )}
          {addressOptions.length > 0 && postcodeIsValid && (
            <>
              <div className="mb-auto">
                <Common.MainTitle>Contact Details</Common.MainTitle>
                <Common.Text>
                  Please enter your details below, we will use this to contact
                  you
                </Common.Text>
                <div className="flex flex-col mt-3">
                  <InputField
                    inputName="firstName"
                    labelText="First Name"
                    placeholder="Enter your first name"
                  />
                  <InputField
                    inputName="lastName"
                    labelText="Last Name"
                    placeholder="Enter your last name"
                  />
                  <div className="flex flex-col mt-4">
                    <label className="text-sm mb-2 font-bold text-primary">
                      Select address for {postcode}
                    </label>
                    <div className="text-sm">
                      <Field
                        as="select"
                        name="addressID"
                        id="addressID"
                        onChange={handleChange}
                        className="w-full text-sm rounded-lg p-3 my-0 border border-light"
                      >
                        <option value={-1} key="please-select">
                          Select your address
                        </option>
                        {addressOptions.map((address) => (
                          <option value={address.udprn} key={address.udprn}>
                            {address.line_1}
                          </option>
                        ))}
                      </Field>
                      <ErrorMessage
                        component="p"
                        className="mt-2 text-sm text-red-600"
                        name="addressID"
                      />
                    </div>

                    <Link
                      to="/get-a-quote"
                      className="text-action underline font-bold text-sm mt-2"
                    >
                      wrong postcode?
                    </Link>
                  </div>
                  <InputField
                    inputName="phoneNumber"
                    labelText="Phone number (mobile preferred)"
                    placeholder="Enter your mobile number"
                  />
                  <InputField
                    inputName="email"
                    optional
                    inputType="email"
                    labelText="Email"
                    placeholder="Enter your email address"
                  />
                </div>
              </div>
              <div className="mt-12">
                <Common.MainTitle>Additional Information</Common.MainTitle>
                <Common.Text className="px-8">
                  Please add any additional information or questions you may
                  have.
                </Common.Text>
                <div className="flex flex-col">
                  <InputField
                    inputName="notes"
                    optional
                    inputType="textarea"
                    labelText="Additional notes (optional)"
                    placeholder="Enter your message"
                  />
                </div>
              </div>

              <div className="pb-32 mt-6">
                <div className="bg-biege flex p-6 space-x-4 mb-6">
                  <div>
                    <Field
                      id="marketingOptIn"
                      name="marketingOptIn"
                      type="checkbox"
                      className={"w-6 h-6 p-4 rounded border-light mt-3 "}
                    />
                    <ErrorMessage
                      component="p"
                      className="mt-2 text-sm text-red-600"
                      name="marketingOptIn"
                    />
                  </div>
                  <div>
                    <label htmlFor="marketingOptIn">
                      I agree for Preen + Clean to use text messaging to keep me
                      informed of our cleaning dates and to answer any
                      questions. I’m happy for Preen + Clean to store my details
                      to help them provide this service.
                    </label>
                  </div>
                </div>
                <Common.Text>
                  If you wish to see our commitment to your date privacy and
                  terms and conditions, then please read our
                  <br />
                  <a href="/privacy-policy" className="underline font-bold">
                    Privacy Policy
                  </a>
                </Common.Text>
              </div>

              <div className="stickyButtonBar">
                <div className="flex flex-1 justify-between items-center p-4 bg-biege lg:bg-transparent">
                  <BackButton />
                  <ScrollDownIcon
                    isVisible={values.lastName !== "" && !isSubmitting}
                  />
                  <NextButton
                    type="submit"
                    isActive={!hasErrors && formHasValues && !isSubmitting}
                    text={isSubmitting ? "Processing..." : undefined}
                  />
                </div>
              </div>
            </>
          )}
        </>
      )}
    </Form>
  );
};

const ContactDetailsFormWrapper = withFormik<
  FormProps,
  ContactDetailsFormInputValue
>({
  mapPropsToValues: (props) => {
    return {
      firstName: props?.intialContactDetails?.firstName ?? "",
      lastName: props?.intialContactDetails?.lastName ?? "",
      addressID: -1,
      town: "",
      email: props?.intialContactDetails?.email ?? "",
      phoneNumber: props?.intialContactDetails?.phoneNumber ?? "",
      notes: props?.intialContactDetails?.notes ?? "",
      marketingOptIn: false,
    } as ContactDetailsFormInputValue;
  },
  validationSchema: ContactDetailsFormSchema,
  handleSubmit: (
    values: ContactDetailsFormInputValue,
    { props, ...actions }
  ) => {
    //console.info("form submitting", values);
    props.handleSubmit(values);
  },
})(ContactDetailsFormUI);

export default ContactDetailsForm;
